<template>
  <b-container fluid>
    <b-row>
      <b-col xl="12" lg="12" sm="12">
            <iq-card>
                <template v-slot:body>
                    <b-overlay :show="loading">
                        <b-row>
                            <b-col xl="12" lg="12" sm="12">
                                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                                    <b-form @submit.prevent="handleSubmit(storeUpdate)" @reset.prevent="reset" enctype="multipart/form-data">
                                        <b-row>
                                            <b-col xl="6" lg="6" sm="12">
                                                <ValidationProvider name="Organization" vid="org_id" rules="required|min_value:1">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="org_id"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                        <template v-slot:label>
                                                           {{$t('org_pro.organization')}} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-select
                                                            plain
                                                            id="org_id"
                                                            :options="orgList"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            v-model="invitation.org_id"
                                                        >
                                                        <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback d-block">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col xl="6" lg="6" sm="12">
                                                <ValidationProvider name="Circular Type" vid="circular_type" rules="required|min_value:1">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="circular_type"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                        <template v-slot:label>
                                                           {{$t('research_manage.circular_type')}} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-select
                                                            plain
                                                            id="circular_type"
                                                            :options="circularTypeList"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            v-model="invitation.circular_type"
                                                        >
                                                        <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback d-block">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col xl="6" lg="6" sm="12" v-if="invitation.circular_type === 1">
                                                <ValidationProvider name="Thematic Area" vid="thematic_area_id" rules="required|min_value:1">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="thematic_area_id "
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                        {{$t('research_manage.thematic_area')}} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-select
                                                        plain
                                                        v-model="invitation.thematic_area_id"
                                                        id="thematic_area_id"
                                                        :options="thematicAreaList"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback d-block">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                        </b-row>
                                        <b-row v-if="invitation.circular_type === 2">
                                            <b-col xl="6" lg="6" sm="12">
                                                <ValidationProvider name="Fiscal Year" vid="fiscal_year_id" rules="required|min_value:1">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="fiscal_year_id "
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                        {{$t('globalTrans.fiscal_year')}} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-select
                                                        plain
                                                        v-model="invitation.fiscal_year_id"
                                                        id="fiscal_year_id"
                                                        :options="fiscalYearList"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback d-block">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col xl="6" lg="6" sm="12">
                                                <ValidationProvider name="Memo No" vid="memo_no" rules="required">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="memo_no "
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                        {{$t('research_manage.memo_no')}} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-input
                                                            id="memo_no"
                                                            v-model="invitation.memo_no"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-input>
                                                        <div class="invalid-feedback d-block">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col xl="6" lg="6" sm="12">
                                            </b-col>
                                        </b-row>
                                        <b-row v-if="invitation.circular_type === 2">
                                            <b-col xl="6" lg="6" sm="12">
                                                <ValidationProvider name="Circular Name (En)" vid="circular_name" rules="required">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="circular_name "
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                        {{$t('research_manage.circular_name_en')}} <span class="text-danger">*</span>
                                                        </template>
                                                            <b-form-input
                                                                id="circular_name"
                                                                v-model="invitation.circular_name"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                            ></b-form-input>
                                                        <div class="invalid-feedback d-block">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col xl="6" lg="6" sm="12">
                                                <ValidationProvider name="Circular Name (Bn)" vid="circular_name_bn" rules="required">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="circular_name_bn "
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                        {{$t('research_manage.circular_name_bn')}} <span class="text-danger">*</span>
                                                        </template>
                                                            <b-form-input
                                                                id="circular_name_bn"
                                                                v-model="invitation.circular_name_bn"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                            ></b-form-input>
                                                        <div class="invalid-feedback d-block">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                        </b-row>
                                        <b-row  v-if="invitation.circular_type === 1">
                                            <div class="col-md-12 dam-form">
                                                <!-- <fieldset class="p-2 w-100"> -->
                                                <!-- <legend class="px-2 w-50 shadow-sm">{{$t('elearning_tpm.course_entry')}}</legend> -->
                                                <div class="col-12">
                                                    <table class="table" style="width:100%" border="1">
                                                    <thead class="thead">
                                                        <tr>
                                                        <th style="width:15%">{{$t('globalTrans.sl_no')}}</th>
                                                        <th style="width:35%">{{$t('research_manage.sector_name')}}</th>
                                                        <th style="width:35%">{{$t('research_manage.sub_sector_name')}}</th>
                                                        <th style="width:15%">{{$t('globalTrans.action')}}</th>
                                                        </tr>
                                                    </thead>
                                                    <tr v-for="(invitationData,index) in invitation.proposal_invitation_details" :key="index">
                                                        <td>
                                                        {{ $n(index + 1) }}
                                                        </td>
                                                        <td>
                                                        <keep-alive>
                                                            <ValidationProvider :vid="'sector_id' + index"  name="Sector name">
                                                                <div slot-scope="{ valid, errors }">
                                                                    <b-form-select
                                                                        plain
                                                                        v-model="invitationData.sector_id"
                                                                        :options="sectorList"
                                                                        @change="getSubSectorList(invitationData)"
                                                                        :id="index"
                                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                                        >
                                                                        <template v-slot:first>
                                                                            <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                                        </template>
                                                                    </b-form-select>
                                                                    <div class="error invalid-feedback" role="alert">
                                                                    {{ errors[0] }}
                                                                    </div>
                                                                </div>
                                                            </ValidationProvider>
                                                        </keep-alive>
                                                        </td>
                                                        <td>
                                                        <keep-alive>
                                                            <ValidationProvider :vid="'sub_sector_id' + index"  name="Sub Sector name">
                                                                <div slot-scope="{ valid, errors }">
                                                                    <b-form-select
                                                                        plain
                                                                        v-model="invitationData.sub_sector_id"
                                                                        :options="invitationData.subSectorList"
                                                                        :id="index"
                                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                                        >
                                                                        <template v-slot:first>
                                                                            <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                                        </template>
                                                                    </b-form-select>
                                                                    <div class="error invalid-feedback" role="alert">
                                                                    {{ errors[0] }}
                                                                    </div>
                                                                </div>
                                                            </ValidationProvider>
                                                        </keep-alive>
                                                        </td>
                                                        <td>
                                                        <b-button variant=" iq-bg-danger" size="sm" @click="remove(index)"><i class="ri-delete-bin-line m-0"></i></b-button>
                                                        <!-- <b-button v-show="index == invitation.proposal_invitation_details.length-1" variant=" iq-bg-success" size="sm" @click="addMore">{{$t('globalTrans.add_more')}}</b-button> -->
                                                        <!-- <b-button v-show="index == invitation.proposal_invitation_details.length-1" variant=" iq-bg-success" size="sm" @click="addMore"><i class="ri-add-line m-0"></i></b-button> -->
                                                        <!-- <b-button v-show="index || ( !index && invitation.proposal_invitation_details.length > 1)" variant=" iq-bg-danger" size="sm" @click="remove(index)"><i class="ri-delete-bin-line m-0"></i></b-button> -->
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="3"></td>
                                                        <td>
                                                            <button
                                                                @click="addMore"
                                                                class="btn btn-sm btn-primary"
                                                                type="button"
                                                            >{{$t('globalTrans.add_more')}}</button>
                                                        </td>
                                                    </tr>
                                                    </table>
                                                </div>
                                                <!-- </fieldset> -->
                                            </div>
                                        </b-row>
                                        <!-- <b-row>
                                            <b-col xl="6" lg="6" sm="12">
                                                <ValidationProvider name="Research Objectives (En)" vid="research_objectives" rules="required">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="research_objectives "
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                        {{$t('research_manage.research_objective_en')}} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-textarea
                                                            row="3"
                                                            id="research_objectives"
                                                            v-model="invitation.research_objectives"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            ></b-textarea>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                        </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col xl="6" lg="6" sm="12">
                                                <ValidationProvider name="Research Objectives (Bn)" vid="research_objectives_bn" rules="required">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="research_objectives_bn "
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                        {{$t('research_manage.research_objective_bn')}} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-textarea
                                                            row="3"
                                                            id="research_objectives_bn"
                                                            v-model="invitation.research_objectives_bn"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            ></b-textarea>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                        </b-row> -->
                                        <b-row class="mt-2">
                                            <b-col xl="6" lg="6" sm="12">
                                                <p class="text-black">{{$t('research_manage.circular_description_en')}} <span class="text-danger">*</span></p>
                                            </b-col>
                                            <b-col xl="6" lg="6" sm="12">
                                                <p class="text-black">{{$t('research_manage.circular_description_bn')}} <span class="text-danger">*</span></p>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col xl="6" lg="6" sm="12">
                                                <ValidationProvider name="Circular Description (En)" vid="circular_description" rules="required">
                                                    <div slot-scope="{ valid, errors }">
                                                        <vue-editor
                                                            id="circular_description"
                                                            v-model="invitation.circular_description"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            ></vue-editor>
                                                            <div class="invalid-feedback d-block">
                                                            {{ errors[0] }}
                                                            </div>
                                                    </div>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col xl="6" lg="6" sm="12">
                                                <ValidationProvider name="Circular Description (Bn)" vid="circular_description_bn" rules="required">
                                                <div slot-scope="{ valid, errors }">
                                                <vue-editor
                                                    id="circular_description_bn"
                                                    v-model="invitation.circular_description_bn"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></vue-editor>
                                                    <div class="invalid-feedback d-block">
                                                    {{ errors[0] }}
                                                    </div>
                                                </div>
                                                </ValidationProvider>
                                            </b-col>
                                        </b-row>
                                        <b-row class="mt-3">
                                            <b-col md="6" sm="12">
                                                <ValidationProvider name="Submission Deadline" vid='submission_deadline' rules="required">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="submission_deadline"
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                        {{ $t('research_manage.submission_deadline')}} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-input
                                                            class="fromDate"
                                                            v-model="invitation.submission_deadline"
                                                            :placeholder="$t('globalTrans.select_date')"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-input>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col xl="6" lg="6" sm="12">
                                                <ValidationProvider name="Attachment" vid="attachment">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    :label="$t('globalTrans.attachment')"
                                                    label-for="attachment"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <b-form-file
                                                    id="attachment"
                                                    v-model="invitation.attachment_file"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-file>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                        </b-row>
                                        <div class="row">
                                            <div class="col-sm-3"></div>
                                            <div class="col text-right">
                                                <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                                &nbsp;
                                                <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                                            </div>
                                        </div>
                                    </b-form>
                                </ValidationObserver>
                            </b-col>
                        </b-row>
                    </b-overlay>
                </template>
            </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { agriResearchServiceBaseUrl } from '../../../../../config/api_config'
import { proposalInvitationStore, proposalInvitationUpdate } from '../../api/routes'
import flatpickr from 'flatpickr'
import { mapGetters } from 'vuex'
import { VueEditor } from 'vue2-editor'

export default {
    props: ['id'],
    components: {
        ValidationObserver,
        ValidationProvider,
        VueEditor
    },
    created () {
        if (this.$store.state.Auth.activeRoleId !== 1) {
            this.invitation.org_id = this.$store.state.Auth.authUser.org_id
            // if (this.$store.state.Auth.authUser.is_org_admin !== 1) {
            //   this.search.coordinator_id = this.$store.state.Auth.authUser.user_id
            // }
        }
        // this.findEquipment()
        if (this.id) {
            const tmp = this.getInvitationData()
            this.invitation = tmp
            const directoryIdData = tmp.proposal_invitation_details.map(item => {
                return { sector_id: parseInt(item.sector_id), sub_sector_id: parseInt(item.sub_sector_id), subSectorList: this.$store.state.AgriResearch.commonObj.subSectorList.filter(subSector => subSector.status === 1 && subSector.sector_id === parseInt(item.sector_id)) }
            })
            this.invitation.proposal_invitation_details = directoryIdData
        }
        // this.getSubSectorList()
    },
    mounted () {
        core.index()
        flatpickr('.fromDate', {})
    },
    data () {
        return {
            loading: false,
            saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
            invitation: {
                org_id: 0,
                fiscal_year_id: 0,
                memo_no: '',
                circular_type: 0,
                thematic_area_id: 0,
                invitation_code: '',
                research_objectives: '',
                research_objectives_bn: '',
                circular_description: '',
                circular_description_bn: '',
                circular_name: '',
                circular_name_bn: '',
                submission_deadline: '',
                attachment_file: [],
                proposal_invitation_details: [
                    {
                        sector_id: 0,
                        sub_sector_id: 0,
                        subSectorList: []
                    }
                ]
            },
            attachmentDemo: [],
            thematicAreaList: [],
            subSectorList: [],
            sectorList: []
        }
    },
    computed: {
        circularTypeList () {
            const circularTypeList = [
                { value: 1, text: this.$i18n.locale === 'en' ? 'Co ordinatinal research' : 'সমন্বিত গবেষণা', text_en: 'ACo ordinatinal researchus', text_bn: 'সমন্বিত গবেষণা' },
                { value: 2, text: this.$i18n.locale === 'en' ? 'Divisional Research' : 'বিভাগীয় গবেষণা', text_en: 'Divisional Research', text_bn: 'বিভাগীয় গবেষণা' }
            ]
            return circularTypeList
        },
        ...mapGetters({
            authUser: 'Auth/authUser'
        }),
        orgList: function () {
        const objectData = this.$store.state.commonObj.organizationProfileList.filter(el => el.status === 0)
        return objectData.map(div => {
                return { value: div.value, text: this.$i18n.locale === 'en' ? div.text_en : div.text_bn }
            })
        },
        fiscalYearList: function () {
        const objectData = this.$store.state.commonObj.fiscalYearList.filter(el => el.status === 0)
        return objectData.map(div => {
                return { value: div.value, text: this.$i18n.locale === 'en' ? div.text_en : div.text_bn }
            })
        }
        // thematicAreaList: function () {
        //     return this.$store.state.AgriResearch.commonObj.thematicAreaList.filter(item => item.status === 1)
        // },
        // sectorList: function () {
        //     return this.$store.state.AgriResearch.commonObj.sectorList.filter(item => item.status === 1)
        // }
    },
    watch: {
        'invitation.org_id': function (newValue, oldValue) {
            if (this.invitation.org_id !== 4) {
                this.invitation.circular_type = 2
            }
            if (newValue !== oldValue) {
                this.thematicAreaList = this.getThematicAreaList(newValue)
                this.sectorList = this.getSectorList(newValue)
            } else {
                this.thematicAreaList = []
                this.sectorList = []
            }
        }
    },
    methods: {
        getThematicAreaList (orgId) {
            if (this.invitation.org_id) {
                const area = this.$store.state.AgriResearch.commonObj.thematicAreaList.filter(item => item.status === 1)
                if (orgId) {
                return area.filter(item => item.org_id === parseInt(orgId))
                }
                return area
            }
        },
        getSectorList (orgId) {
            if (this.invitation.org_id) {
                const area = this.$store.state.AgriResearch.commonObj.sectorList.filter(item => item.status === 1)
                if (orgId) {
                return area.filter(item => item.org_id === parseInt(orgId))
                }
                return area
            }
        },
        getInvitationData () {
            const tmpData = this.$store.state.list.find(item => item.id === this.id)
            return JSON.parse(JSON.stringify(tmpData))
        },
        // onFileChange (e) {
        //     this.attachmentDemo = e.target.files[0]
        // },
        async storeUpdate () {
            this.loading = true
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            try {
                let result = null
                const loadinState = { loading: false, listReload: true }
                const config = {
                    headers: { 'content-type': 'multipart/form-data' }
                }
                var formData = new FormData()
                Object.keys(this.invitation).map(key => {
                    if (key === 'attachment_file') {
                        formData.append('attachment', this.invitation.attachment_file)
                    } else {
                        formData.append(key, this.invitation[key])
                    }
                })

                formData.append('proposal_invitation_details', JSON.stringify(this.invitation.proposal_invitation_details))
                if (this.invitation.id) {
                    formData.append('_method', 'PUT')
                    result = await RestApi.postData(agriResearchServiceBaseUrl, `${proposalInvitationUpdate}/${this.id}`, formData, config)
                } else {
                    result = await RestApi.postData(agriResearchServiceBaseUrl, proposalInvitationStore, formData, config)
                }
                this.$store.dispatch('mutateCommonProperties', loadinState)
                this.loading = false
                if (result.success) {
                    this.$toast.success({
                        title: 'Success',
                        message: this.id ? 'Data updated successfully' : 'Data save successfully',
                        color: '#D6E09B'
                    })
                    this.$bvModal.hide('modal-4')
                } else {
                    this.$toast.error({
                        title: 'Error',
                        message: 'Operation failed! Please, try again.'
                    })
                    this.$refs.form.setErrors(result.errors)
                }
            } catch (error) {
                this.$toast.error({
                title: 'Error',
                message: 'something went wrong! Please, try again.'
                })
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            }
        },
        getSubSectorList (invitationData) {
            const subSectorList = this.$store.state.AgriResearch.commonObj.subSectorList.filter(item => item.status === 1)
            if (invitationData.sector_id) {
                invitationData.subSectorList = subSectorList.filter(subsector => subsector.sector_id === invitationData.sector_id)
            }
        },
        addMore () {
            this.invitation.proposal_invitation_details.push({
                sector_id: 0,
                sub_sector_id: 0
            })
            // const tmpEquipment = {
            //     sector_id: 0,
            //     sub_sector_id: 0
            // }
            // const key1 = parseInt(this.invitation.proposal_invitation_details.length - 1)
            // const item = this.invitation.proposal_invitation_details[key1]
            // let isEmpty = true
            // Object.keys(item).map(key => {
            //     if (item[key] === '' || item[key] === 0) {
            //         isEmpty = false
            //     }
            // })
            // if (isEmpty === true) {
            //     this.invitation.proposal_invitation_details.push(tmpEquipment)
            // }
        },
        remove (key) {
            this.invitation.proposal_invitation_details.splice(key, 1)
        }
    }
}

</script>
