import i18n from '@/i18n'
import Store from '@/store'
// import ReportHeading from '@/Utils/report-head'
import { dateFormat } from '@/Utils/fliter'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
const exportPdfDetails = async (reportTitle, invitationData, invitationDetails, vm) => {
  try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
      const pdfContent = []
      pdfContent.push({ text: reportTitle, style: 'hh', alignment: 'center' })
        pdfContent.push(
          {
            table: {
              style: 'tableData',
              headerRows: 0,
              widths: ['20%', '3%', '27%', '20%', '3%', '27%'],
              body: [
                [
                  { text: vm.$t('research_manage.invitation_code'), style: 'th', alignment: 'left' },
                  { text: ':', style: 'td', alignment: 'center' },
                  { text: invitationData.invitation_code, style: 'td', alignment: 'left' },
                  { text: vm.$t('research_manage.thematic_area'), style: 'th', alignment: 'left' },
                  { text: ':', style: 'td', alignment: 'center' },
                  { text: vm.$i18n.locale === 'bn' ? invitationData.thematic_name_bn : invitationData.thematic_name, style: 'td', alignment: 'left' }
                ],
                // [
                //   { text: vm.$t('research_manage.research_objective'), style: 'th', alignment: 'left' },
                //   { text: ':', style: 'td', alignment: 'center' },
                //   { text: vm.$i18n.locale === 'bn' ? invitationData.research_objectives_bn : invitationData.research_objectives, style: 'td', alignment: 'left' },
                //   { text: vm.$t('research_manage.circular_description'), style: 'th', alignment: 'left' },
                //   { text: ':', style: 'td', alignment: 'center' },
                //   { text: vm.$i18n.locale === 'bn' ? invitationData.circular_description_bn : invitationData.circular_description, style: 'td', alignment: 'left' }
                // ],
                [
                  { text: vm.$t('research_manage.circular_description'), style: 'th', alignment: 'left' },
                  { text: ':', style: 'td', alignment: 'center' },
                  { text: vm.$i18n.locale === 'bn' ? invitationData.circular_description_bn : invitationData.circular_description, style: 'td', alignment: 'left' },
                  { text: vm.$t('research_manage.submission_deadline'), style: 'th', alignment: 'left' },
                  { text: ':', style: 'td', alignment: 'center' },
                  { text: dateFormat(invitationData.submission_deadline), style: 'td', alignment: 'left' }
                ]
              ]
            },
            layout: {
              hLineWidth: function (i, node) {
                return 0
              },
              vLineWidth: function (i, node) {
                return 0
              }
            }
          }
        )
        pdfContent.push({ text: vm.$t('research_manage.sector'), style: 'hh', alignment: 'center' })
        // option
          let allRows = []
          allRows = [
            [
              { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center' },
              { text: vm.$t('research_manage.sector_name'), style: 'th', alignment: 'center' },
              { text: vm.$t('research_manage.sub_sector_name'), style: 'th', alignment: 'center' }
            ]
          ]
          // table body
          invitationDetails.filter((data, optionIndex) => {
            const rowItem = [
              { text: vm.$n(optionIndex + 1), style: 'td', alignment: 'center' },
              { text: vm.$i18n.locale === 'bn' ? vm.$store.state.AgriResearch.commonObj.sectorList.find(doc => doc.value === parseInt(data.sector_id)).text_bn : vm.$store.state.AgriResearch.commonObj.sectorList.find(doc => doc.value === parseInt(data.sector_id)).text_en, style: 'td', alignment: 'center' },
              { text: vm.$i18n.locale === 'bn' ? vm.$store.state.AgriResearch.commonObj.subSectorList.find(doc => doc.value === parseInt(data.sub_sector_id)).text_bn : vm.$store.state.AgriResearch.commonObj.subSectorList.find(doc => doc.value === parseInt(data.sub_sector_id)).text_en, style: 'td', alignment: 'center' }
            ]
            allRows.push(rowItem)
          })
          pdfContent.push({ text: '', style: 'tableMargin' })
          pdfContent.push({
            table: {
              headerRows: 1,
              widths: ['20%', '40%', '40%'],
              body: allRows
            }
          })

        pdfContent.push({ text: '', style: 'fertilizer', alignment: 'center' })
        const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'portrait',
        watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
            th: {
              fontSize: 10,
              margin: [3, 3, 3, 3]
            },
            th1: {
                fontSize: 9
              },
            td: {
              fontSize: 8,
              margin: [3, 3, 3, 3]
            },
            search: {
              fontSize: 8,
              margin: [3, 3, 3, 3]
            },
            fertilizer: {
              margin: [0, 10, 0, 7]
            },
            fertilizerSHeader: {
                fontSize: 10,
                margin: [40, 0, 0, 0]
            },
            hh: {
                fontSize: 14,
                bold: true,
                margin: [10, 10, 25, 20]
            },
            header: {
              fontSize: 12,
              margin: [0, 0, 0, 4]
            },
            header2: {
              fontSize: 10,
              margin: [0, 10, 0, 20]
            },
            headerPort1: {
              fontSize: 10,
              margin: [0, 20, 0, 5]
            },
            headerPort: {
              fontSize: 10,
              margin: [0, 4, 0, 15]
            },
            krishi: {
              margin: [0, 1, 0, 15],
              alignment: 'center'
            },
            header3: {
              fontSize: 9,
              margin: [0, 15, 0, 0]
            },
            address: {
              fontSize: 9,
              margin: [0, -10, 0, 0]
            },
            tableSubHead: {
              margin: [0, 5, 0, 15]
            }
          }
        }
        pdfMake.createPdf(docDefinition, null, null, null).download('proposal-invitation-details')
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
