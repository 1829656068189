<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('research_manage.proposal_invitation') }} {{ $t('globalTrans.search') }}</h4>
      </template>
      <template v-slot:body>
        <b-row>
            <b-col lg="6" md="5" sm="12" xs="12">
              <b-form-group
                  class="row"
                  label-cols-sm="3"
                  :label="$t('globalTrans.organization')"
                  label-for="org_id"
              >
                  <b-form-select
                      plain
                      id="org_id"
                      :options="orgList"
                      v-model="search.org_id"
                      placeholder=""
                  >
                  <template v-slot:first>
                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                  </b-form-select>
              </b-form-group>
          </b-col>
            <b-col lg="6" md="6" sm="12" xs="12">
                <b-form-group
                class="row"
                label-cols-sm="3"
                :label="$t('research_manage.thematic_area')"
                label-for="thematic_area_id"
                >
                <b-form-select
                plain
                v-model="search.thematic_area_id"
                :options="thematicAreaList"
                id="thematic_area_id"
                >
                <template v-slot:first>
                    <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                </template>
                </b-form-select>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="6" sm="12">
                <ValidationProvider name="From Date" vid='from_date'>
                    <b-form-group
                        class="row"
                        label-cols-sm="3"
                        label-for="from_date"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                        {{ $t('globalTrans.from_date')}}
                        </template>
                        <b-form-input
                            class="fromDate"
                            v-model="search.from_date"
                            :placeholder="$t('globalTrans.select_date')"
                            :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                        <div class="invalid-feedback">
                        {{ errors[0] }}
                        </div>
                    </b-form-group>
                </ValidationProvider>
            </b-col>
            <b-col md="6" sm="12">
                <ValidationProvider name="To Date" vid='to_date'>
                    <b-form-group
                        class="row"
                        label-cols-sm="3"
                        label-for="to_date"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                        {{ $t('globalTrans.to_date')}}
                        </template>
                        <b-form-input
                            class="fromDate"
                            v-model="search.to_date"
                            :placeholder="$t('globalTrans.select_date')"
                            :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                        <div class="invalid-feedback">
                        {{ errors[0] }}
                        </div>
                    </b-form-group>
                </ValidationProvider>
            </b-col>
        </b-row>
        <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('research_manage.proposal_invitation') }} {{ $t('globalTrans.list') }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button class="btn_add_new" v-b-modal.modal-4 @click="resetId">
              <i class="fas fa-plus"></i>{{ $t('globalTrans.add_new') }}
            </b-button>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table thead-class="bg-primary" bordered hover :items="listData" :emptyText="$t('globalTrans.noDataFound')" show-empty :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(org_id)="data">
                      {{ getOrgName(data.item.org_id) }}
                    </template>
                    <template v-slot:cell(submission_deadline)="data">
                        {{ data.item.submission_deadline | dateFormat }}
                    </template>
                    <template v-slot:cell(thematic_name)="data">
                        {{ data.item.circular_type === 2 ? ($i18n.locale === 'en' ? data.item.circular_name : data.item.circular_name_bn) : ($i18n.locale === 'en' ? data.item.thematic_name : data.item.thematic_name_bn) }}
                    </template>
                    <template v-slot:cell(status)="data">
                      <span class="badge badge-info" v-if="data.item.status === 1">{{$t('globalTrans.not_publish')}}</span>
                      <span class="badge badge-success" v-if="data.item.status === 2">{{$t('globalTrans.publish')}}</span>
                    </template>
                    <template v-slot:cell(action)="data">
                      <b-button v-b-modal.modal-4 variant=" iq-bg-success mr-1" size="sm" @click="edit(data.item)" title="Edit"><i class="ri-ball-pen-fill m-0"></i></b-button>
                      <b-button v-if="data.item.status === 1 && data.item.project_setup_status === 1" variant=" iq-bg-success" size="sm" @click="remove(data.item)" title="Publish"><i class="fa fa-paper-plane" aria-hidden="true"></i></b-button>
                      <b-button class="mr-1" title="View" v-b-modal.modal-5 variant=" iq-bg-success" size="sm" @click="details(data.item)"><i class="ri-eye-line m-0 "></i></b-button>
                    </template>
                  </b-table>
                  <b-pagination
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-4" size="xl" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <FormV :id="editItemId" :key="editItemId"/>
      </p>
    </b-modal>
    <b-modal id="modal-5" size="xl" :title="$t('research_manage.proposal_invitation') + ' ' + $t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
       <!-- <b-button @click="exportPDF" class="ml-4 btn-success water-test-pdf-button">
         {{ $t('globalTrans.pdf') }}
       </b-button> -->
       <Details :item="item" :key="testId" ref="details"></Details>
    </b-modal>
  </b-container>
</template>
<script>
import FormV from './Form'
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { proposalInvitationList, proposalInvitationToggleStatus } from '../../api/routes'
import Details from './Details'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import { mapGetters } from 'vuex'
import flatpickr from 'flatpickr'
import Store from '@/store'
// import ExportPdf from './directoryPDF'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    FormV, Details
  },
  data () {
    return {
      baseUrl: agriResearchServiceBaseUrl,
      search: {
        org_id: 0,
        thematic_area_id: '0',
        from_date: '',
        to_date: ''
      },
      editItemId: 0,
      directroy: {
        pump_directory_equipments: [
          {
            master_equipment_type_id: '0'
          }
        ]
      },
      testId: 0,
      longitude: '',
      districtList: [],
      thematicAreaList: [],
      upazilaList: [],
      unionList: [],
      rows: [],
      item: ''
    }
  },
  async created () {
    // this.search = Object.assign({}, this.search, {
    //   org_id: this.authUser.org_id
    // })
    if (this.$store.state.Auth.activeRoleId !== 1) {
      this.search.org_id = this.$store.state.Auth.authUser.org_id
      // if (this.$store.state.Auth.authUser.is_org_admin !== 1) {
      //   this.search.coordinator_id = this.$store.state.Auth.authUser.user_id
      // }
    }
    this.loadData()
  },
  watch: {
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    },
    'search.org_id': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.thematicAreaList = this.getThematicAreaList(newValue)
      } else {
        this.thematicAreaList = []
      }
    }
  },
  computed: {
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(el => el.status === 0)
    },
    ...mapGetters({
      authUser: 'Auth/authUser'
    }),
    // thematicAreaList: function () {
    //     return this.$store.state.AgriResearch.commonObj.thematicAreaList.filter(item => item.status === 1)
    // },
    formTitle () {
       return (this.editItemId === 0) ? this.$t('research_manage.proposal_invitation') + ' ' + this.$t('globalTrans.entry') : this.$t('research_manage.proposal_invitation') + ' ' + this.$t('globalTrans.update')
    },
    currentLocale () {
      return this.$i18n.locale
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('globalTrans.organization'), class: 'text-left' },
          { label: this.$t('research_manage.invitation_code'), class: 'text-left' },
          { label: this.$t('research_manage.circular_name'), class: 'text-left' },
          { label: this.$t('research_manage.submission_deadline'), class: 'text-left' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'org_id' },
          { key: 'memo_no' },
          { key: 'thematic_name' },
          { key: 'submission_deadline' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'org_id' },
          { key: 'memo_no' },
          { key: 'thematic_name' },
          { key: 'submission_deadline' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    }
  },
  mounted () {
    this.loadData()
    flatpickr('.fromDate', {})
  },
  methods: {
    getOrgName (id) {
        const Obj = this.$store.state.commonObj.organizationProfileList.find(item => item.value === parseInt(id))
        if (Obj) {
            if (this.$i18n.locale === 'en') {
                return Obj.text_en
            } else {
                return Obj.text_bn
            }
        } else {
                return ''
        }
    },
    getThematicAreaList (orgId) {
      if (this.search.org_id) {
        const area = this.$store.state.AgriResearch.commonObj.thematicAreaList.filter(item => item.status === 1)
        if (orgId) {
          return area.filter(item => item.org_id === parseInt(orgId))
        }
        return area
      }
    },
    details (item) {
      this.item = item
      this.testId = item.id
    },
    edit (item) {
      this.editItemId = item.id
    },
    searchData () {
      if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 0) {
        this.search = Object.assign({}, this.search, {
          org_id: this.authUser.office_detail.org_id,
          division_id: this.authUser.office_detail.division_id,
          district_id: this.authUser.office_detail.district_id,
          upazilla_id: this.authUser.office_detail.upazilla_id
        })
      }
      this.loadData()
    },
    changeStatus (baseUrl, uri, item, destination = null, dropdownName = null) {
          Store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
          RestApi.deleteData(baseUrl, `${uri}/${item.id}`).then(response => {
            if (response.success) {
              /** The following line commented as list is reloaded */
              // Store.dispatch('toggleStatus', item)
              if (destination !== null && dropdownName !== null) {
                Store.dispatch('toggleDropdownItemStatus', { itemId: item.id, destination: destination, dropdownName: dropdownName })
              }
              window.vm.$toast.success({
                title: 'Success',
                message: 'Data Submitted Successfully',
                color: '#D6E09B'
              })
            } else {
              window.vm.$toast.error({
                title: 'Error',
                message: 'Operation failed! Please, try again.'
              })
            }
            Store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
          })
    },
    remove (item) {
            window.vm.$swal({
            title: window.vm.$t('globalTrans.publishMsg'),
            showCancelButton: true,
            confirmButtonText: window.vm.$t('globalTrans.yes'),
            cancelButtonText: window.vm.$t('globalTrans.no'),
            focusConfirm: false
        }).then((result) => {
            if (result.isConfirmed) {
                this.changeStatus(agriResearchServiceBaseUrl, proposalInvitationToggleStatus, item)
            }
        })
    },
    // remove (item) {
    //   this.changeStatus(agriResearchServiceBaseUrl, proposalInvitationToggleStatus, item)
    // },
    loadData () {
        const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        RestApi.getData(agriResearchServiceBaseUrl, proposalInvitationList, params).then(response => {
          if (response.success) {
            this.$store.dispatch('setList', this.dataList(response.data.data))
            this.paginationData(response.data)
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        })
    },
    dataList (data) {
      const listData = data.map(item => {
        const thematicAreaObj = this.$store.state.AgriResearch.commonObj.thematicAreaList.find(Item => Item.value === item.thematic_area_id)
          const thematicAreaData = {}
          if (typeof thematicAreaObj !== 'undefined') {
            thematicAreaData.thematic_name = thematicAreaObj.text_en
            thematicAreaData.thematic_name_bn = thematicAreaObj.text_bn
          } else {
            thematicAreaData.thematic_name = ''
            thematicAreaData.thematic_name_bn = ''
          }
        return Object.assign({}, item, thematicAreaData)
      })
      return listData
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)

      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }

      return districtList
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)

      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }

      return upazilaList
    },
    getUnionList (upazilaId = null) {
      const unionList = this.$store.state.commonObj.unionList.filter(item => item.status === 0)
      if (upazilaId) {
        return unionList.filter(union => union.upazilla_id === upazilaId)
      }
      return unionList
    },
    exportPDF () {
      this.$refs.details.pdfExport()
    //   const reportTitle = this.$i18n.locale === 'en' ? 'Complain Application' : 'অভিযোগের আবেদন'
    //   ExportPdf.exportPdfDetails(agriResearchServiceBaseUrl, '/report-heading/detail', 4, reportTitle, this.complain, this.appHierarchyNames, this.org_name, this)
    }
  }
}
</script>
